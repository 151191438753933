// src/components/Hero.js
import React from 'react';

const Hero = () => {
  return (
    <section id="hero" style={{ height: '100vh', background: '#333', color: '#fff' }}>
      <h1>Amanda Mock</h1>
      <p>Technical Product & Operations Specialist</p>
    </section>
  );
}

export default Hero;
