// src/App.js
import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';  // You will create this later
import Portfolio from './components/Portfolio';  // You will create this later
import Contact from './components/Contact';  // You will create this later
import Footer from './components/Footer';  // You will create this later

function App() {
  return (
    <div>
      <Header />
      <Hero />
      {/* Add the other components later */}
    </div>
  );
}

export default App;
